"use strict";
exports.__esModule = true;
exports.initSkipLink = void 0;
var initSkipLink = function () {
    // Inspired by https://github.com/selfthinker/dokuwiki_template_writr/blob/master/js/skip-link-focus-fix.js
    var skiplink = document.querySelector('[data-skiplink]');
    var main = document.querySelector('main');
    var handleBlur = function () {
        main.removeAttribute('tabindex');
        main.removeEventListener('blur', handleBlur);
        main.removeEventListener('focusout', handleBlur);
    };
    var handleClick = function () {
        main.setAttribute('tabindex', -1);
        main.addEventListener('blur', handleBlur);
        main.addEventListener('focusout', handleBlur);
        main.focus();
    };
    if (skiplink && main) {
        skiplink.addEventListener('click', handleClick);
    }
};
exports.initSkipLink = initSkipLink;
