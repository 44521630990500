"use strict";
/**
 * Initialises the Submenus within the primary Wagtail menu (excluding the Explorer menu)
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
exports.__esModule = true;
exports.initSubmenus = void 0;
var initSubmenus = function () {
    var primaryNavContainer = document.querySelector('[data-nav-primary]');
    if (!primaryNavContainer) {
        return;
    }
    var subMenuTriggers = document.querySelectorAll('[data-nav-primary-submenu-trigger]');
    var activeClass = 'submenu-active';
    __spreadArray([], __read(subMenuTriggers)).forEach(function (subMenuTrigger) {
        subMenuTrigger.addEventListener('click', function (clickEvent) {
            var submenuContainer = subMenuTrigger.parentNode;
            primaryNavContainer.classList.remove(activeClass);
            __spreadArray([], __read(subMenuTriggers)).forEach(function (sm) { return sm.classList.remove(activeClass); });
            primaryNavContainer.classList.toggle(activeClass);
            submenuContainer.classList.toggle(activeClass);
            document.addEventListener('mousedown', function (e) {
                if (!submenuContainer.contains(e.target) &&
                    subMenuTrigger !== e.target) {
                    primaryNavContainer.classList.remove(activeClass);
                    submenuContainer.classList.remove(activeClass);
                }
            });
            document.addEventListener('keydown', function (e) {
                // IE11 uses "Esc" instead of "Escape"
                if (e.key === 'Escape' || e.key === 'Esc') {
                    primaryNavContainer.classList.remove(activeClass);
                    submenuContainer.classList.remove(activeClass);
                }
            });
            clickEvent.preventDefault();
        });
    });
};
exports.initSubmenus = initSubmenus;
